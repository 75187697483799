<template>
  <div>
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <v-row class="bradcrumb">
      <router-link :to="{ name: 'home' }" class="root"> Dashboard </router-link>
      /
      <router-link :to="{ name: 'admin-master' }" class="root">
        Admin Master
      </router-link>
      <span class="child">/ Academic Year</span>
    </v-row>
    <br />
    <v-card class="card">
      <v-card-title class="heading justify-center">
        <span>Academic Year</span>
      </v-card-title>
      <v-card-text>
        <div class="add-section">
          <div style="text-align: center">
            <v-col>
              <v-form ref="form">
                <label class="add-text">Add New Academic Year</label>
                <v-text-field
                  class="text"
                  v-model="academicyear"
                  outlined
                  dense
                  :rules="[rules.required]"
                  autocomplete="off"
                  clearable
                  required
                ></v-text-field>
                <label class="add-text">Previous Year </label>
                <v-select
                  class="text"
                  :items="aylist"
                  item-text="ay"
                  item-value="id"
                  v-model="prevacademicyear"
                  autocomplete="off"
                  outlined
                  dense
                  clearable
                  required
                ></v-select>
                <label class="add-text">Next Year </label>
                <v-select
                  class="text"
                  :items="aylist"
                  item-text="ay"
                  item-value="id"
                  v-model="nextacademicyear"
                  autocomplete="off"
                  outlined
                  dense
                  clearable
                  required
                ></v-select>
                <v-btn color="success" @click="addacademicyear">Add</v-btn>
              </v-form>
            </v-col>
          </div>
        </div>

        <template>
          <div class="text-center">
            <v-dialog v-model="dialog" width="500">
              <v-card>
                <v-card-title class="grey lighten-2">
                  Edit Academic Year
                </v-card-title>

                <v-card-text style="padding-bottom: 0px">
                 <div class="add-section">
          <div style="text-align: center">
            <v-col>
              <v-form ref="form">
                <label class="add-text">Add New Academic Year</label>
                <v-text-field
                  class="text"
                  v-model="editacademicyear"
                  outlined
                  dense
                  :rules="[rules.required]"
                  autocomplete="off"
                  clearable
                  required
                ></v-text-field>
                <label class="add-text">Previous Year </label>
                <v-select
                  class="text"
                  :items="aylist"
                  item-text="ay"
                  item-value="id"
                  v-model="editprevacademicyear"
                  autocomplete="off"
                  outlined
                  dense
                  clearable
                  required
                ></v-select>
                <label class="add-text">Next Year </label>
                <v-select
                  class="text"
                  :items="aylist"
                  item-text="ay"
                  item-value="id"
                  v-model="editnextacademicyear"
                  autocomplete="off"
                  outlined
                  dense
                  clearable
                  required
                ></v-select>
              
              </v-form>
            </v-col>
          </div>
        </div>
                </v-card-text>

                <!-- <v-divider></v-divider> -->

                <div class="text-center">
                  <v-spacer></v-spacer>
                  <v-btn class="m-3" @click="dialog = false"> Close </v-btn>
                  <v-btn class="m-3 green white--text" @click="saveEdit()">Update</v-btn>
                </div>
              </v-card>
            </v-dialog>
          </div>
        </template>

        <template v-if="aydata != null">
          <v-data-table
            :headers="headers"
            :items="aydata"
            :search="search"
            class="elevation-1 nowrap"
          >
            <template v-slot:top>
              <v-toolbar flat color="white">
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search in table"
                  single-line
                  hide-details
                ></v-text-field>
              </v-toolbar>
            </template>
            <template v-slot:item.edit="{ item }">
              <!-- <v-icon v-if="item.edit==true" color="success" class="mr-2">mdi-check-circle-outline</v-icon> -->
              <v-icon v-if="item" class="mr-2" @click="updateay(item)"
                >mdi mdi-pencil-box</v-icon
              >
            </template>
             <template v-slot:item.actions="{ item }">
                            <!-- <v-btn :loading="edit_btn_load" @click="editItem(item)" class="link" color="info"> <v-icon title="Activate/Deactivate the User Type">mdi-recycle</v-icon></v-btn> -->
                            <v-switch v-model="item.isactive" @change="editItem(item)"></v-switch>
                        </template>

                          <template v-slot:item.action="{ item }">
                            <!-- <v-btn :loading="edit_btn_load" @click="editItem(item)" class="link" color="info"> <v-icon title="Activate/Deactivate the User Type">mdi-recycle</v-icon></v-btn> -->
                            <v-switch v-model="item.iscurrent" @change="editcurrent(item)"></v-switch>
                        </template>
                        
            <template v-slot:item.delete="{ item }">
              <v-icon v-if="item" class="mr-2" @click="deleteAcademicyear(item.id)">
                mdi mdi-delete</v-icon
              >
            </template>
          </v-data-table>
        </template>
      </v-card-text>
    </v-card>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import axios from "axios";
import "@/assets/css/color.css"; // css needs to be imported for each dashboard
export default {
  data: () => ({
    overlay: false,
    snackbar_msg: "",
    snackbar: false,
    color: "",
    org: null,
    dialog: false,
    search: "",
    academicyear: null,
    prevacademicyear: null,
    nextacademicyear: null,
    editacademicyear: null,
    editprevacademicyear: null,
    editnextacademicyear: null,
    ayid: null,
    programName: null,
    programtypeName: null,
    userType: null,
    edit_btn_load: false,
    isactive:"",
    iscurrent:"",
    rules: {
      required: (value) => !!value || "Required.",
    },
    aylist: null,
    aydata: null,
    headers: [
      { text: "Sr no", value: "srno" },
      { text: "Academic Year", value: "ay" },
      { text: "Next Academic year", value: "next" },
      { text: "Prev Academic year ", value: "prev" },
      { text: "Edit", value: "edit" },
       { text: "Isactive", value: "actions", sortable: false },
        { text: "Iscurrent", value: "action", sortable: false },
      { text: "Delete", value: "delete" },
      // { text: "Toggle", value: "actions", sortable: false },
    ],
  }),
  watch: {},
  methods: {
    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },
    // editItem(item) {
    //   console.log(item);
    //   axios
    //     .post("/admin/currencyIsactive", item)
    //     .then(() => {
    //       // this.sec_mode_list = res.data.user_list;
    //       this.showSnackbar("#4CAF50", "Program Type updated successfully...");
    //     })
    //     .catch((error) => {
    //       this.showSnackbar("#b71c1c", "Something went wrong!!!");
    //       console.log(error);
    //     })
    //     .finally(() => {
    //       this.edit_btn_load = false;
    //       axios;
    //     });
    // },
     editItem(item) {
              //  alert(item.isactive);
                 const data = { 
                        
                        Isactive:item.isactive,
                        id:item.id
                };
                axios
                    .post("/admin/academicyearIsactive", data)
                    .then((res) => {
                         if (res.data.msg == "200") {
                                this.showSnackbar("#4CAF50",  "Is active successfully");
                               this.onload();
                            }
                        
                    })
                    .catch((error) => {
                        this.showSnackbar("#b71c1c", "Something went wrong");
                        console.log(error);
                    })
                    .finally(() => {
                        this.edit_btn_load = false;
                        axios;
                    });
            },

           editcurrent(item){
            //  alert(item.iscurrent);
 const data = { 
                        Iscurrent:item.iscurrent,
                        id:item.id
                };
                axios
                    .post("/admin/academicyearIscurrent", data)
                    .then((res) => {
                         if (res.data.msg == "200") {
                                this.showSnackbar("#4CAF50",  "Is current successfully");
                               this.onload();
                            }
                        
                    })
                    .catch((error) => {
                        this.showSnackbar("#b71c1c", "Something went wrong");
                        console.log(error);
                    })
                    .finally(() => {
                        this.edit_btn_load = false;
                        axios;
                    });
           },

    updateay(item) {
      this.dialog = true;
      this.ayid = item.id;
      this.editacademicyear = item.ay;
      this.editprevacademicyear = item.previd;
      this.editnextacademicyear = item.nextid;
    },

    saveEdit() {
        
      if (this.editacademicyear) {
        this.editacademicyear = this.editacademicyear.trim();
      }
      if (this.editnextacademicyear == null) {
        this.editnextacademicyear = "";
      }
      if (this.editprevacademicyear==null) {
        this.editprevacademicyear = "";
      }
      const data = {
          ayid:this.ayid,
        ay: this.editacademicyear,
        next: this.editnextacademicyear,
        prev: this.editprevacademicyear,
      };

      if (this.editacademicyear) {
        axios
          .post("/admin/editacademicyear", data)
          .then((res) => {
          
            
            if (res.data.status == "success") {
              this.showSnackbar(
                "#4CAF50",
                "Ay added successfully..."
              );
              this.editacademicyear = "";
              this.editnextacademicyear = "";
              this.editprevacademicyear = "";
              this.dialog=false;
              this.onload();
            } else {
              this.showSnackbar("#b71c1c", res.data.status);
            }
          })
          .catch((error) => {
            this.showSnackbar("#b71c1c", "Something went wrong!!!");
            console.log(error);
          })
          .finally(() => {});
      } else {
        this.showSnackbar("#b71c1c", "Please enter Academic Year ");
        this.$refs.form.validate();
      }
    },
    deleteAcademicyear(item) {
      const data = {
        ayid: item,
      };
      axios
        .post("/admin/deleteAcademicyear", data)
        .then((res) => {
          if (res.data.msg == "200") {
            this.showSnackbar(
              "#4CAF50",
              "Ay Deleted Successfully  !!"
            );
            this.onload();
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong!!!");
          window.console.log(error);
        });
    },

    onload() {
      this.overlay = true;
      axios.post("/admin/getacademicyearlist_data").then((res) => {
        this.overlay = false;
        if (res.data.msg == "200") {
          this.aylist = res.data.aylist;
          this.aydata = res.data.aydata;
        } else {
          this.overlay = false;
        }
      });
    },

    addacademicyear() {
       
      if (this.academicyear) {
        this.academicyear = this.academicyear.trim();
      }
      if (this.nextacademicyear == null) {
        this.nextacademicyear = "";
      }
      if (this.prevacademicyear==null) {
        this.prevacademicyear = "";
      }
      const data = {
        ay: this.academicyear,
        next: this.nextacademicyear,
        prev: this.prevacademicyear,
      };

      if (this.academicyear) {
        axios
          .post("/admin/saveacademicyear", data)
          .then((res) => {
            
            
            if (res.data.status == "success") {
              this.showSnackbar(
                "#4CAF50",
                "Ay added successfully..."
              );
              this.academicyear = "";
              this.nextacademicyear = "";
              this.prevacademicyear = "";
              this.onload();
            } else {
              this.showSnackbar("#b71c1c", res.data.status);
            }
          })
          .catch((error) => {
            this.showSnackbar("#b71c1c", "Something went wrong!!!");
            console.log(error);
          })
          .finally(() => {});
      } else {
        this.showSnackbar("#b71c1c", "Please enter Academic Year ");
        this.$refs.form.validate();
      }
    },
  },
  mounted() {
    this.onload();
  },
};
</script>
<style scoped>
.heading {
  background-color: #3f51b5;
  padding: 0.3rem;
  color: white;
}
.v-icon {
  cursor: pointer;
}
.text >>> .v-input__slot {
  background: white !important;
  border-color: hsla(190, 99%, 30%, 1) !important;
}
</style>
